var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "echart",
    },
    [
      _c(
        "div",
        { staticClass: "pie" },
        [
          _c("vue-echarts", {
            ref: "chart",
            staticStyle: { height: "100%", width: "98%" },
            attrs: { option: _vm.options },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }